<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="创建权限组" width="70%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="权限分组">
                        <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form.auth" :options="auths" placeholder="请选择" class="w200"/>
                    </a-form-model-item>
                    <div style="width: 1000px">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th style="width: 200px">权限名称</th>
                                <th>
                                    <a-input v-model="form.name" class="w300"/>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" style="font-weight: bold">页面权限</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(sidebar_list_item,sidebar_list_key) in auth_list.sidebar_list">
                                <td>
                                    <a-checkbox v-model="sidebar_list_item.check" @change="checkPageAll(sidebar_list_key)">
                                        {{sidebar_list_item.meta_title }}
                                    </a-checkbox>
                                </td>
                                <td>
                                    <div class="authmain">
                                        <div class="authmainlist" v-for="(sidebar_list_item_children_item,sidebar_list_item_children_key) in sidebar_list_item.children">
                                            <a-checkbox :label="sidebar_list_item_children_item.meta_title" v-model="auth_list.sidebar_list[sidebar_list_key].children[sidebar_list_item_children_key].check" @change="onChange">
                                                {{sidebar_list_item_children_item.meta_title}}
                                            </a-checkbox>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="layui-table" style="margin-top: -11px">
                            <thead>
                            <tr>
                                <th colspan="2" style="font-weight: bold">操作权限</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(api_list_item,api_list_key) in auth_list.api_list">
                                <td>
                                    <a-checkbox v-model="api_list_item.check" @change="checkcaozuoAll(api_list_key)">
                                        {{ api_list_item.title }}
                                    </a-checkbox>
                                </td>
                                <td>
                                    <div class="authmain">
                                        <div class="authmainlist" v-for="(api_list_item_children_item,api_list_item_children_key) in api_list_item.children">
                                            <a-checkbox :label="api_list_item_children_item.title" v-model="auth_list.api_list[api_list_key].children[api_list_item_children_key].check" @change="onChange">
                                                {{api_list_item_children_item.title}}
                                            </a-checkbox>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <a-form-model-item label="" :wrapper-col="{ span: 14, offset: 1 }">
                        <a-checkbox v-model="check_active" @change="onChange1">创建之后选中</a-checkbox>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 1 }">
                        <a-button type="primary" @click="doClick1()">
                            创建
                        </a-button>
                        <br>
                        <a @click="authocick">前往权限管理页面></a>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'chooseDo', 'getStype'],
        data() {
            return {
                auto_choose: true,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                check_active: true,
                auths: [],
                form: {
                    name: '',
                    order: '0'
                },
                type_list: [],
                auth_list: false,
            }
        },
        mounted() {
            this.getStype1()
            this.Authalllist() //权限列表
        },
        methods: {

            checkPageAll(sidebar_list_key) {
                this.auth_list.sidebar_list[sidebar_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.sidebar_list[sidebar_list_key].children[key], 'check', this.auth_list.sidebar_list[sidebar_list_key].check)
                })
            },
            checkcaozuoAll(api_list_key) {
                this.auth_list.api_list[api_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.api_list[api_list_key].children[key], 'check', this.auth_list.api_list[api_list_key].check)
                })
            },

            authocick(item) {
                this.$router.push('/auth/index/0');
                this.close();
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            getStype1() {
                this.$sa0.post({
                    url: this.$api('Quanxianlist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.auths = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Authalllist() {
                this.$sa0.post({
                    url: this.$api('Get_admin_auth'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let sidebar_list = response.data.list1.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            let api_list = response.data.list3.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            this.auth_list = {
                                api_list, sidebar_list
                            };
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doClick1() {
                let auth_arr = [];
                this.auth_list.sidebar_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                this.auth_list.api_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                let data = {
                    id: this.form.id,
                    name: this.form.name,
                    show: 1,
                    type: 2,
                    status: 1,
                    pid: this.form.auth[this.form.auth.length - 1],
                    auth: auth_arr.join(","),
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_auth'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            if (this.check_active) {
                                let ids = [
                                    ...this.form.auth,
                                    Number(res.data.id)
                                ]
                                this.getStype(ids)
                            }
                            // console.log(response.data.id)
                            // this.getStype()
                            this.close()
                            layer.msg(res.message)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            onChange1(e) {
                console.log(`checked = ${e.target.checked}`);
            },

            onChecked(_, e, checkedKeys, itemSelect) {
                const {eventKey} = e.node;
                itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
            },
        }
    }
</script>
<style scoped>

    .sidebar_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_item_remark_wrapper{
        margin-left: 24px;
        }

    .api_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .sidebar_item_remark_wrapper{
        margin-left: 24px;
        }

    .sidebar_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .api_list_wrapper{
        margin-bottom: 30px;
        }

    .api_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }


    .sidebar_list_wrapper{
        margin-bottom: 30px;
        margin-top: 30px;

        }

    .sidebar_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }

    .admin_info_wrapper{
        display: none;
        width: 900px;
        padding: 30px;
        }

    .button_wrapper{
        margin-bottom: 30px;
        }

    .w200{ width: 200px}

    .authmain{ display: flex; flex-wrap: wrap}

    .authmainlist{ margin: 5px}

</style>
