
<template>
    <div>
        <a-modal title="编号规则列表" okText="确定" cancelText="取消" width="1200px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <!--仪器编号规则列表-->
            <table class="layui-table">
                <tr>
<!--                    <th>编号分类</th>-->
                    <th>编号名称</th>
                    <th>前缀</th>
                    <th>日期格式</th>
                    <th>流水号</th>
                    <th>流水号增量</th>
                    <th>流水号更新间隔</th>
                    <th>完整编号示例</th>
                    <th>是否为默认规则</th>
                    <th>操作</th>
                </tr>
                <tr v-for="(item,key) in rulenum_list">
<!--                    <td>{{wordTypeFenlei(item.num_type)}}</td>-->
                    <td>{{item.name}}</td>
                    <td>{{item.num_pre}}</td>
                    <td>{{wordTypeData(item.data_format)}}</td>
                    <td>{{item.serial_num}}</td>
                    <td>{{item.serial_increment}}</td>
                    <td>{{ wordTypeUpate(item.update_type)}}</td>
                    <td>{{item.serialnumber}}</td>
                    <td>{{wordTypeMoren(item.rules_default)}}</td>
                    <td>
                        <a-button type="danger" @click="doClick(item)">选择</a-button>
                    </td>
                </tr>
            </table>
        </a-modal>
    </div>
</template>
<script>

    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel','callback'],
        data() {
            return {
                rulenum_list:[],
                id:'',
            }
        },
        mounted() {
            this.Get_rulenum()// w编号规则列表
        },
        methods: {

            doClick(item) {
                let data = {
                    id: item.id,
                }
                //console.log(JSON.stringify(data))
                console.log(data.id)
                this.$sa0.post({
                    url:  this.$api('Get_serialnumber'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.callback(res.data.serialnumber)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            // 类型转换-编号分类
            wordTypeFenlei(num_type) {
                let type_arr = ['委托单编号', '样品编号', '人员编号', '仪器编号']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(num_type) - 0]
                } else {
                    return num_type
                }
            },
            // 类型转换-日期格式
            wordTypeData(data_format) {
                let type_arr = ['年月日时分秒 - 例：20211109203736','年月日时分 - 例：202111092037', '年月日时 - 例：2021110920', '年月日 - 例：20211109', '年月 - 例：202111','年 - 例：2021']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(data_format) - 0]
                } else {
                    return data_format
                }
            },
            // 类型转换-时间间隔
            wordTypeUpate(update_type) {
                let type_arr = ['不更新','按年更新', '按月更新', '按天更新']
                if (type_arr.length >= Number(update_type)) {
                    return type_arr[Number(update_type) - 0]
                } else {
                    return update_type
                }
            },
            // 类型转换-是否默认
            wordTypeMoren(rules_default) {
                let type_arr = ['是','否']
                if (type_arr.length >= Number(rules_default)) {
                    return type_arr[Number(rules_default) - 0]
                } else {
                    return rules_default
                }
            },
            // w编号规则列表
            Get_rulenum() {
                this.$sa0.post({
                    url: this.$api('Get_rulenum'),
                    data: {
                        num_type:2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rulenum_list = response.data.list;
                            // console.log(this.rulenum_list)
                            // console.log(JSON.stringify(this.rulenum_list))
                            //return
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },
            // 定义 组件 自身的确定函数
            handleOk() {
                this.ok()
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
        }
    }
</script>
<style scoped>

</style>
